import Axios from "axios";

// Create Axios Instance
const axios = Axios.create();
axios.defaults.headers.Accept = "application/json";

// Add request interceptor to dynamically set the Authorization header
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.data?.status !== 200) {
      const error = new Error();
      error.message = response.data.error?.message || "An error occurred";
      error.fields = response.data.error?.fields || {};
      error.status = response.data.status;
      throw error;
    }
    return response.data.data;
  },
  (error) => {
    const errorObj = new Error();

    // Handle specific HTTP status codes
    if (error.response) {
      switch (error.response.status) {
        case 404:
          errorObj.message = "Resource not found";
          break;
        case 401:
          errorObj.message = "Unauthorized access";
          break;
        case 403:
          errorObj.message = "Access forbidden";
          break;
        case 500:
          errorObj.message = "Server error";
          break;
        default:
          errorObj.message = error?.response?.data?.error?.message || "An unexpected error occurred";
      }
      errorObj.status = error.response.status;
      errorObj.fields = error?.response?.data?.error?.fields || {};
    } else if (error.request) {
      // Request was made but no response received
      errorObj.message = "No response from server";
      errorObj.status = 0;
    } else {
      // Error in setting up the request
      errorObj.message = "Failed to make request";
      errorObj.status = 0;
    }

    throw errorObj;
  }
);

export default axios;
