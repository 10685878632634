import { createContext } from "react";
import { IntlProvider } from "react-intl";

// Import your translation files
import useApp from "src/hooks/useApp";
import enMessages from "../i18n/messages/en.json";
import trMessages from "../i18n/messages/tr.json";

export const LocaleContext = createContext();

const messages = {
  en: enMessages,
  tr: trMessages,
};

export const LocaleProvider = ({ children }) => {
  /** */

  const { locale } = useApp();

  return (
    <LocaleContext.Provider value={{ locale }}>
      <IntlProvider messages={messages[locale]} locale={locale} defaultLocale="en" onError={() => {}}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
