import { SOCKET_BASE_URL } from "@config";
import { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useAuth } from "./AuthContext";

export const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  /** */

  const { token } = useAuth();

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    /** */

    if (!token) return;

    // Create Socket.IO Instance
    const instance = io(SOCKET_BASE_URL, {
      transports: ["websocket"],
      autoConnect: true,
      auth: {
        token,
      },
    });

    // Socket Connected
    instance.on("connect", () => console.log("Connected to socket server"));

    // Socket Disconnected
    instance.on("disconnect", () => console.log("Disconnected from socket server"));

    // Socket Connection Error
    instance.on("connect_error", (error) => console.error("Socket connection error:", error.message));

    // Set Socket Instance
    setSocket(instance);

    // Cleanup on unmount
    return () => instance.disconnect();
  }, [token]);

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};

export const useSocket = () => useContext(SocketContext);
