import { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  /** */

  // App Locale
  const [locale, setLocale] = useState(localStorage.getItem("locale") || "en");

  // TODO: Check Company Plugin Settings, Basic Information, etc.

  return (
    <AppContext.Provider
      value={{
        locale,
        setLocale: (code) => {
          localStorage.setItem("locale", code);
          setLocale(code);
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
