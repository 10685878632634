import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

const MessengerPage = lazy(() => import("src/modules/messenger/MessengerPage"));
const Error404 = lazy(() => import("src/modules/errors/404/Error404"));
const Error503 = lazy(() => import("src/modules/errors/503/Error503"));

const AppRoutes = () => {
  // Suspensed View with better loading state
  const SV = ({ element }) => (
    <Suspense
      fallback={
        <div className="flex items-center justify-center min-h-screen">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
        </div>
      }
    >
      {element}
    </Suspense>
  );

  return (
    <Routes>
      <Route path="/:company/messenger/*" element={<SV element={<MessengerPage />} />} />
      <Route path="/404" element={<SV element={<Error404 />} />} />
      <Route path="/503" element={<SV element={<Error503 />} />} />
      {/* <Route path="*" element={<Navigate to="/404" replace />} /> */}
    </Routes>
  );
};

export default AppRoutes;
