import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { AppProvider } from "./contexts/AppContext";
import { AuthProvider } from "./contexts/AuthContext";
import { LocaleProvider } from "./contexts/LocaleContext";
import { SocketProvider } from "./contexts/SocketContext";
import AppRoutes from "./routes/AppRoutes";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppProvider>
          <AuthProvider>
            <LocaleProvider>
              <SocketProvider>
                <AppRoutes />
              </SocketProvider>
            </LocaleProvider>
          </AuthProvider>
        </AppProvider>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
