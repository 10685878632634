import { API_BASE_URL } from "@config";
import queryString from "query-string";
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "src/helpers/axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  /** */

  const navigate = useNavigate();

  // Token State
  const [token, setToken] = useState(null);

  // Get CompanyID
  const company = useLocation().pathname.split("/")[1];

  // Check Parent URL
  const parentURL = queryString.parse(useLocation().search)?.parentURL; // Received as a Query from Containgin Iframe

  // Check Visitor Mutation
  const CVM = useMutation(
    async () => {
      const response = await axios.post(`${API_BASE_URL}/${company}/visitor/check`, {
        token: localStorage.getItem("token"),
        page: {
          URL: parentURL,
        },
      });
      return response;
    },
    {
      onSuccess: (response) => {
        if (response?.token) {
          localStorage.setItem("token", response.token); // Persist Token in LocalStorage
          setToken(response.token); // Persist Token in Context
        }
        if (response?.contactRequired) navigate(`/${company}/messenger/contact`, { replace: true });
        else navigate(`/${company}/messenger/chat`, { replace: true });
      },
      onError: (error) => {
        return console.log(error);
      },
    }
  );

  useEffect(() => {
    if (company) CVM.mutate();
  }, [company]);

  return <AuthContext.Provider value={{ token }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
